import React from 'react';
import './UpdatedBanner.css'; // Add CSS to style the banner as needed
import Locale from 'translations';

const Banner = () => {
  const { UpdateBanner } = Locale;

  return (
    <div className="banner">
      <label>
       {UpdateBanner?.title} 
        <a href="https://po.malaebapp.com/" target="_blank" rel="noopener noreferrer">
          {UpdateBanner?.action}
        </a>
      </label>
    </div>
  );
};

export default Banner;
