import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { useMalaebState } from './context/global';
import Locale from './translations';
import ReactNotification from 'react-notifications-component';
import React, { Suspense } from 'react';
import Loader from 'components/LoadingPage/LoadingPage';
import Routes from './routes';
import AxiosConfiguration from 'helpers/axiosConfiguration';
import ErrorBoundary from 'components/Errors/ErrorBoundary/ErrorBoundary';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-notifications-component/dist/theme.css';
import 'scss/style.scss';
import { useSelector } from 'react-redux';
import { RootState } from 'Store';
import { Main, Sidebar, UpdateBanner } from 'components';

declare global {
  interface Window {
    intercomSettings: string;
  }
}

function App() {
  const { APP_ID, isAuth, locale } = useMalaebState();
  const selectedStadium = useSelector((state: RootState) => state.calendar.selectedStadium)

  Locale.setLanguage(locale);
  document
    .getElementsByTagName('html')[0]
    .setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr');

  // Axios Configurations
  AxiosConfiguration();
  (function () {
    var w = global; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); }
    else { var d = document; var i: any = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + APP_ID; var x = d.getElementsByTagName('script')[0]; x?.parentNode?.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } }
  })();

  return (
    <>
      <div
        style={{
          direction: locale === 'ar' ? 'rtl' : 'ltr',
          textAlign: locale === 'ar' ? 'right' : 'left',
          fontFamily: locale === 'ar' ? 'Cairo' : 'proxa',
        }}
      >
        <ReactNotification />
        <ErrorBoundary>
          <UpdateBanner />
          <Main />
          <Router>
            {isAuth && selectedStadium != undefined ? <Sidebar /> : null}
            <Suspense fallback={<Loader />}>
              {Routes}
            </Suspense>
          </Router>
        </ErrorBoundary>
      </div>
    </>
  );
}

export default App;
