import React, { Dispatch } from 'react'
import Locale from 'translations'
import { useAddNotification } from 'Pages/CalendarPage/Helpers/addNotification'
import { CalendarSliceActions } from 'Store/Calendar'
import { Stadium } from 'services/user/me'
import { BookingState } from '../Store/Calendar/props'

const getPitch = (props: {
  stadium: Stadium
  dispatchRedux: Dispatch<any>
  getPitchesOfStadiumRequest: any
  bookingState: BookingState
  getHiddenPitches?: boolean
}) => {
  const { messages } = Locale
  props
    ?.getPitchesOfStadiumRequest({ stadiumId: props?.stadium?.id })
    .then((data: any) => {
      if (data?.error) {
        useAddNotification(
          data?.error?.data?.message,
          messages?.warning,
          'danger',
        )
      } else {
        props?.dispatchRedux(
          CalendarSliceActions?.setPitchesforStadium(data?.data?.data),
        )
        if (location?.pathname !== '/') {
          props?.dispatchRedux(
            CalendarSliceActions.setPitchId(Number(data?.data?.data?.[0]?.id)),
          ) // current pitch id
          props?.dispatchRedux(
            CalendarSliceActions.setPitch(data?.data?.data?.[0]),
          ) // current pitch
          props?.dispatchRedux(
            CalendarSliceActions.setBookingState({
              ...props?.bookingState,
              pitch_id: Number(data?.data?.data?.[0]?.id),
            }),
          )
        } else props?.dispatchRedux(CalendarSliceActions.setPitchId(0))
      }
    })
    .catch(() => {
      useAddNotification(messages.errorMsg, messages?.warning, 'danger')
    })

  if (props?.getHiddenPitches) {
    props
      ?.getPitchesOfStadiumRequest({
        stadiumId: props?.stadium?.id,
        query: 'with_hidden=1',
      })
      .then((data: any) => {
        if (data?.error) {
          useAddNotification(
            data?.error?.data?.message,
            messages?.warning,
            'danger',
          )
        } else {
          props?.dispatchRedux(
            CalendarSliceActions.setPitchesOfStadiumWithHiddenPitches(
              data?.data?.data,
            ),
          )
        }
      })
  }
}

export default getPitch
